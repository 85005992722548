import { useEffect, useState, useGlobal } from 'reactn';
import '@brainhubeu/react-carousel/lib/style.css';
import { connectWallet, getTreeRoot } from './lib/blockchain';
import Mint from './components/Mint';
import $ from 'jquery';
import { Toaster } from 'react-hot-toast';


window.$ = $;

function getChildIndex(node) {
    return Array.prototype.indexOf.call(node.parentNode.childNodes, node);
}

function App() {

    
    
    const [address, useAddress] = useGlobal('address');
    const [sliderVal, setSliderVal] = useState(0);
    var dist_top = 0;
    var active_menu_item = null;

    const scrollTo = (dest) => {        
        console.log(dest);
        var offset = window.innerWidth <= 760 ? 0 : 55;
        window.TweenMax.to('html,body', 0.6, {
            scrollTop: ($(dest).offset().top - dist_top) + offset,
            ease: window.Power4.easeInOut
        });
    }

    const handleScroll = () => {

        var mobile = window.innerWidth <= 760;
        
        const sections = document.querySelectorAll('.section');

        
        sections.forEach(section => {
            
            var menu_item;
            
            if(mobile){
                menu_item = document.querySelector('.mobile-button[data-section="' + section.getAttribute('data-section') + '"]');
            }else{
                menu_item = document.querySelector('.panel-button[data-section="' + section.getAttribute('data-section') + '"]');
            }
            
            if(menu_item){
                
                var in_view;

                if(mobile){
                    in_view = window.innerHeight / 2 > section.getBoundingClientRect().top && window.innerHeight / 2 < section.getBoundingClientRect().bottom;
                }else{
                    in_view = section.getBoundingClientRect().top < dist_top && section.getBoundingClientRect().top + section.getBoundingClientRect().height > dist_top;
                }
                
                if(in_view){
                    
                    menu_item.classList.add("active");
                    // adjust selected option on mobile
                    // window.innerWidth / 
                    console.log('adjust buttons');
                    console.log(getChildIndex(menu_item));
                    var center = (window.innerWidth / 2) - (128 * (getChildIndex(menu_item))) - 64;
                    
                    // 207 - 64 = 143
                    // 207 - 128 - 64 = 15
                    // 207 - 256 - 64 = -113
                    // 207 = 

                    console.log(center);
                    
                    document.querySelector('.mobile-buttons').style.left = center + 'px';
                }else{
                    menu_item.classList.remove("active");
                }
            }
            
        });
   
    }

    const handleResize = () => {
        
        var mobile = window.innerWidth <= 760;

        $('.panel-left').css({left: (window.innerWidth / 2) - window.innerHeight * 0.75});
        $('.panel-right').css({left: (window.innerWidth / 2) + window.innerHeight * 0.45});

        
        var panel = document.querySelector('.panel');
        var content = document.querySelector('.content');
        var panel_border = document.querySelector('.panel-border');
        

        if(mobile){
            dist_top = 0;
        }else{
            var panel_height = panel.getBoundingClientRect().height;
            dist_top = ((window.innerHeight - panel_height) / 2) + (window.innerWidth / 45);
        }
        
        // panel.style.top = dist_top + 'px';
        // panel_border.style.top = dist_top + 'px';
        // content.style.paddingTop = dist_top + 'px';
        // mask.style.height = dist_top + 'px';

    }
    

    useEffect(() => {
        
        window.addEventListener('scroll', handleScroll, true);
        window.addEventListener('resize', handleResize, true);
        
        getTreeRoot();

        // place the mobile buttons on the center at the start
        var center = (window.innerWidth / 2) - 64;
        document.querySelector('.mobile-buttons').style.left = center + 'px';
        
        handleResize();
        // connectWallet();

    }, []);

    return (

        <div className="App">

            <Toaster containerStyle={{ top: 70 }} toastOptions={{ style: { color: '#000', fontWeight: 'bold' }, }} />
            
            <div className="mask desktop-only"></div>

            <div className="desktop-only panel-container">
            
                <div className="panel desktop-only">

                    <div className="logo" onClick={() => scrollTo('.section-home')}>
                        <img src="/images/logo.png" />
                    </div>
                    
                    <div className="panel-left">
                        <div className="panel-button button-1" data-section="welcome" onClick={() => scrollTo('.section-welcome')}></div>
                        <div className="panel-button button-2" data-section="vision" onClick={() => scrollTo('.section-vision')}></div>
                        <div className="panel-button button-3" data-section="universe" onClick={() => scrollTo('.section-universe')}></div>
                        <div className="panel-button button-7" onClick={() => window.open('https://discord.gg/KxbAxx85He')} data-section="contact"></div>
                    </div>

                    <div className="panel-right">
                        <div className="panel-button button-4" data-section="mint" onClick={() => scrollTo('.section-mint')}></div>
                        <div className="panel-button button-5" data-section="roadmap" onClick={() => scrollTo('.section-roadmap')}></div>
                        <div className="panel-button button-6" data-section="team" onClick={() => scrollTo('.section-team')}></div>
                        <div className="panel-button button-8" data-section="faq" onClick={() => scrollTo('.section-faq')}></div>
                    </div>

                    <div className="panel-bottom desktop-social">
                        
                        <a href="https://twitter.com/VRFutureNFT" target="_blank"><img src="/images/twitter2.svg" style={{ height: 27 }} /></a>
                        <a href="https://www.instagram.com/vrfuturenft/" target="_blank"><img src="/images/instagram.svg" style={{ height: 27 }} /></a>
                        <a href="https://discord.gg/KxbAxx85He" target="_blank"><img src="/images/discord2.svg" style={{ height: 27 }} /></a>
                        <a href="https://www.facebook.com/VRFutureNFT" target="_blank"><img src="/images/facebook2.svg" style={{ height: 27 }} /></a>
                        <a href="https://www.youtube.com/c/VRFutureNFT" target="_blank"><img src="/images/youtube.svg" style={{ height: 38 }} /></a>
                        
                        {!address &&
                            <button className="wallet-button" onClick={() => connectWallet()}>CONNECT&nbsp;WALLET</button>
                        }
                        {!!address &&
                            <div className="wallet-button inline-block">{address.substr(2, 4) + '...' + address.substr(-4, 4)}</div>
                        }
                        
                    </div>

                    <div className="panel-border"></div>

                </div>

            </div>


            <div className="panel mobile-only">

                <div className="mobile-header">
                    <div>
                        <img src="/images/logo.png" style={{marginTop: 8, width: 90, height: 'auto'}} />
                    </div>
                    <div className="mobile-social">
                        <a href="https://twitter.com/VRFutureNFT" target="_blank"><img src="/images/twitter2.svg" /></a>
                        <a href="https://www.instagram.com/vrfuturenft/" target="_blank"><img src="/images/instagram.svg" /></a>
                        <a href="https://discord.gg/KxbAxx85He" target="_blank"><img src="/images/discord2.svg" style={{ height: 22 }} /></a>
                        <a href="https://www.facebook.com/VRFutureNFT" target="_blank"><img src="/images/facebook2.svg" style={{ height: 22 }} /></a>
                        {!address &&
                            <a href="# " onClick={() => connectWallet()} className="mobile-wallet-button">connect</a>
                        }
                        {!!address &&
                            <div className="mobile-address">{address.substr(2, 3) + '...' + address.substr(-3, 3)}</div>
                        }
                    </div>
                </div>

                <div className="panel-border-mobile"></div>

            </div>
            
            <div className="mobile-bottom-panel mobile-only">
                <div className="mobile-buttons-track mobile-only">
                    <div className="mobile-buttons">
                        <div data-section="welcome" className="mobile-button button-1" onClick={() => scrollTo('.section-welcome')}></div>
                        <div data-section="vision" className="mobile-button button-2" onClick={() => scrollTo('.section-vision')}></div>
                        <div data-section="universe" className="mobile-button button-3" onClick={() => scrollTo('.section-universe')}></div>
                        <div data-section="mint" className="mobile-button button-4" onClick={() => scrollTo('.section-mint')}></div>
                        <div data-section="roadmap" className="mobile-button button-5" onClick={() => scrollTo('.section-roadmap')}></div>
                        <div data-section="team" className="mobile-button button-6" onClick={() => scrollTo('.section-team')}></div>
                    </div>
                </div>
            </div>

            <div className="content">

            <div className="notched md:p-20 section section-mint" data-section="mint">        
                <div className="section-content">
                    <div className="mint-title">
                        <img src="/mint.svg" />
                    </div>
                    <div className="mint-illustration">
                        <img src="/mint-illustration.png" />
                    </div>
                    <div className="mint-content">
                        <h2>We are sold out</h2>
                        <a href="https://opensea.io/collection/vrfuturenft" target="_blank"><img src="/images/opensea.png" style={{width: 40, margin: '0 auto'}} /></a>
                    </div>
                </div>
            </div>

                <div className="notched md:p-20 section section-welcome" data-section="welcome" style={{backgroundColor: '#383234'}}>
                    <div className="section-content text-center">
                        <img src="/images/VR Future.png" className="mx-auto mb-8" style={{width: 140}} />
                        <h2 className="text-orange">Welcome to the future</h2>
                        <p>VR Future NFTs are the building blocks of a VR entertainment platform, hosted on the Ethereum blockchain.</p>
                    </div>
                </div>

                <div className="notched md:p-20 section section-vision" data-section="vision" style={{backgroundColor: '#301aff'}}>
                    <div className="section-content">
                        <img src="/images/Vision.png" className="mx-auto mb-8" style={{width: 140}} />
                        <h2>Vision</h2>
                        <div className="pl-4 md:pl-8 pr-12">
                            VR Future is a VR/AR (XR) entertainment company with a three-fold vision:
                        </div>
                        <div className="pl-8 md:pl-16 pt-6 pr-12">
                            <div className="uppercase font-bold">Production</div>
                            Building exciting VR content and offering grants to cutting-edge creators
                        </div>
                        <div className="pl-16 md:pl-24 pt-6 pr-12">
                            <div className="uppercase font-bold">Distribution</div>
                            Hosting top-quality festivals and exhibitions around the world
                        </div>
                        <div className="pl-24 md:pl-32 pt-6 pr-12">
                            <div className="uppercase font-bold">Education</div>
                            Building global capacity via educational workshops and training in developing markets
                        </div>
                    </div>
                </div>

                <div className="notched md:p-20 section section-universe" data-section="universe">
                    
                    <div className="section-content">
                        
                        <img src="/images/solar-system.png" className="mx-auto mb-12" style={{width: '100%'}} />
                        <h2 className="text-center text-orange">Enter our universe</h2>
                        <div className="">
                            <p>
                                Welcome to the future. Many long-imagined technologies are now with us—quantum computing, tiny, powerful batteries, life-extending nanotechnology, and a space industry that has enabled us to colonise the solar system—and further.
                            </p>
                            <p>
                                But the most transformative thing in our lives is a powerful virtual and augmented reality technology that we call Gear. It looks like a mask, or a pair of glasses. When you are old enough, you get your first personal Gear—opening up a whole new universe.
                            </p>
                            <p>
                                With Gear, you can work, learn and play in an infinite number of virtual worlds, and navigate rich layers of added meaning in this world.
                            </p>
                            <p>
                                Gear technology, whose full name is Assisted User Gear, or AUG, makes this possible. We control our Gear through our minds. This takes years to learn, but once we’ve mastered our Gear, we become more powerful, more creative, more ourselves than our ancestors could ever imagine. You can get Gear from a big corporation, or from your neighbourhood hackers, but no matter where you get it, Gear has changed every part of our lives.
                            </p>
                            <p>
                                Imagine controlling a massive machine, and sending it to the stars with a wish. Imagine being a doctor observing the smallest details within the body, or a musician composing with your mind. Imagine walking on the surface of Jupiter, or arguing with the Greek philosophers of antiquity, or travelling through your hometown on a path only you can see.
                            </p>
                            <p>
                                We share our journey with Androids—artificial intelligences downloaded into humanoid bodies—and Aliens, who invite us to join a wider galaxy, and help preserve the light of consciousness forever.
                            </p>
                            <p>
                                Using our Gear, we do more than learn, work and play. We also fight. The power of Gear has unleashed many opportunities, but it also means that some people have succumbed to the madness of mind viruses. We keep our minds, and our weapons, sharp.
                            </p>
                            <p>
                                Corporate militias, gangs, resistance fighters and cults battle on our streets, using Gear to leave secret messages for their allies and traps for their enemies.
                            </p>
                            <p>
                                And while war on a large scale has long been just a memory, there are whispers and warnings all around us. Not everyone trusts the motivations of the Androids and Aliens that walk among us. They say they are here to help, but what if they want to control? And, what happens if the mysterious Ancients—gargantuan, dormant aliens that have been discovered on remote rocks—wake up?
                            </p>
                            
                            
                        </div>
                        
                    </div>

                </div>

                <div className="notched md:p-20 section section-roadmap" data-section="roadmap">
                    
                        <img src="/images/roadmap.svg" />
                    
                </div>
                <div className="notched md:p-20 section section-team" data-section="team" style={{backgroundSize: '80%', backgroundPosition: 'center 5%', backgroundRepeat: 'no-repeat', backgroundImage: 'url(/images/top_stars.png)'}}>
                    <div className="section-content">
                        <h2 className="text-center text-orange">The team</h2>
                        
                        <div className="text-center">
                            A diverse pool of entrepreneurs, producers, artists, VR experts, writers, web3 developers and community managers spanning the globe
                        </div>

                        <div className="team-members grid grid-cols-2 md:grid-cols-3 gap-4">
                            <div className="team-member">
                                <div><img src="/images/team/_0010_FAZ.jpg" /></div>
                                <div className="member-name">FAZΞ</div>
                                <div>Founder and CEO</div>
                            </div>
                            <div className="team-member">
                                <div><img src="/images/team/_0001_VIRGILE.jpg" /></div>
                                <div className="member-name">Virgile</div>
                                <div>Founder and Director Events</div>
                            </div>
                            <div className="team-member">
                                <div><img src="/images/team/_0004_RAYMEEM.jpg" /></div>
                                <div className="member-name">RayMeem</div>
                                <div>Strategy Lead</div>
                            </div>
                            <div className="team-member">
                                <div><img src="/images/team/_0002_UMER.jpg" /></div>
                                <div className="member-name">Umer</div>
                                <div>Creative Director</div>
                            </div>
                            <div className="team-member">
                                <div><img src="/images/team/_0009_LEWANAY.jpg" /></div>
                                <div className="member-name">Lewanay</div>
                                <div>Art Director</div>
                            </div>
                            <div className="team-member">
                                <div><img src="/images/team/_0007_MARGARET.jpg" /></div>
                                <div className="member-name">Margaret</div>
                                <div>Loremaster, Project Consultant</div>
                            </div>
                            
                            <div className="team-member">
                                <div><img src="/images/team/SQUIRRELS.jpg" /></div>
                                <div className="member-name">Squirrels</div>
                                <div>Community Manager</div>
                            </div>
                            
                            <div className="team-member">
                                <div><img src="/images/team/_0000_SLIGHTLYFIA.jpg" /></div>
                                <div className="member-name">SlightlyFia</div>
                                <div>Graphic Designer</div>
                            </div>
                            <div className="team-member">
                                <div><img src="/images/team/_0006_MUJIMAN.jpg" /></div>
                                <div className="member-name">Mujiman</div>
                                <div>Project Manager Dev</div>
                            </div>
                            <div className="team-member">
                                <a href="https://dondo.dev" target="_blank">
                                    <div><img src="/images/team/_0012_AGU.jpg" /></div>
                                    <div className="member-name">Agu</div>
                                    <div>Web3 Developer</div>
                                </a>
                            </div>
                            <div className="team-member">
                                <div><img src="/images/team/_0003_SARA.jpg" /></div>
                                <div className="member-name">Alphawhale</div>
                                <div>AR Developer</div>
                            </div>
                            <div className="team-member">
                                <div><img src="/images/team/_0005_LUCAS.jpg" /></div>
                                <div className="member-name">LucasGuzman3D</div>
                                <div>3D Artist</div>
                            </div>
                        </div>

                        <div className="flags">
                            <img src="/images/flags/fr.svg" />
                            <img src="/images/flags/tr.svg" />
                            <img src="/images/flags/pk.svg" />
                            <img src="/images/flags/at.svg" />
                            <img src="/images/flags/us.svg" />
                            <img src="/images/flags/gb.svg" />
                            <img src="/images/flags/nl.svg" />
                            <img src="/images/flags/ar.svg" />
                            <img src="/images/flags/de.svg" />
                            <img src="/images/flags/ca.svg" />
                        </div>

                        
                    </div>
                </div>
                <div className="notched md:p-20 section section-faq" data-section="faq">
                    <div className="section-content">
                        
                        <h2 style={{textTransform: 'none'}}>FAQs</h2>
                        
                        <div className="faq-question">
                            <div className="faq-q">How much to mint a VR Future NFT and are there any limitations?</div>
                            <div className="faq-a">We won’t be setting the exact mint price until close to launch day, as we want to make sure that the cost plus gas is affordable to new and experienced collectors and is in line with projects of similar value! There is a limit of 10 mints per wallet.</div>
                        </div>

                        <div className="faq-question">
                            <div className="faq-q">Will there be early-access opportunities?</div>
                            <div className="faq-a">There sure will—community members who get involved building our Discord and supporting the project will be eligible for early minting.</div>
                        </div>

                        <div className="faq-question">
                            <div className="faq-q">How many VR Future GENESIS NFTs are available?</div>
                            <div className="faq-a">There will be 7777 GENESIS NFTs released in total, of which 250 will be reserved for marketing purposes and 25 will be reserved for the team. There will also be five hand-drawn 1/1 ultra-rares that are essential to our story—with one of these available to a lucky winner before mint day!</div>
                        </div>

                        <div className="faq-question">
                            <div className="faq-q">What is the VR Future App?</div>
                            <div className="faq-a">The VR Future mobile application enables each VR Future NFT holder to compete daily, weakly and monthly in competitions to win all kinds of amazing prizes including VR headsets, merchandise, all expense paid trips for international festivals along with NFTs and exclusive airdrops.</div>
                        </div>

                        <div className="faq-question">
                            <div className="faq-q">What are the royalty fees?</div>
                            <div className="faq-a">Royalty fees are 5% on OpenSea (excluding 2.5% OpenSea selling fees)</div>
                        </div>

                        {/* <div className="faq-question">
                            <div className="faq-q">What is the VR Future smart contract address?</div>
                            <div className="faq-a">[To be added]</div>
                        </div> */}

                        <div className="faq-question">
                            <div className="faq-q">What cool stuff is coming?</div>
                            <div className="faq-a">Alongside early access for GENESIS holders to the immersive experiences in our mobile app, we will be creating a metaverse presence for the GENESIS collection, and offering exclusive access to holder benefits, including giveaways, exclusive talks and performances, and VR/AR wearables, including AR versions of your GENESIS character’s on AUG.</div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        
        </div>
    

        
    );
}

export default App;

import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import App from './App';

// global storage
setGlobal({
    address: null,
    chainId: null,
    contractAddress: null,
    loadingContract: false,
    loadingChain: false,
    nftMinted: false,
    remaining: null,
    minting: false,
});


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
